
/*!
 * reset
 */
// html { font-size: 14px;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,form,fieldset,p,button,blockquote{margin: 0;padding: 0;}
a:focus, button:focus { outline: none !important;}
button::-moz-focus-inner {border: 0 !important;}
*{margin: 0; box-sizing: border-box;}
*:focus {outline: none !important;}
input,button,select,textarea {outline: none !important;}
input,select,button{vertical-align:middle !important}
label {margin-bottom: 0 !important;}
h1 {margin: 0;}
p {margin: 0 !important;}
ul,ol {padding-left: 0;list-style: none !important;}
dl {margin: 0 !important;}
dt,dd {line-height: inherit !important;}
dt {font-weight: inherit !important;}
dd {margin-bottom: 0 !important;}
cite {font-style: normal !important;}
form {margin-bottom: 0 !important;}
blockquote {
    padding-left: 0 !important;
    border-left: 0 !important;
}
address {margin-top: 0;margin-bottom: 0;}
figure {margin-bottom: 0;}
a,a:hover {
    cursor: pointer;
    color: inherit !important;
    text-decoration: none !important;
}
button {
    cursor: pointer;
}
table{
    border-collapse:collapse;
    border-spacing:0;
}
iframe{vertical-align:top}
body {-ms-word-break:break-all;word-break:break-all;}

html, body { 
    background: radial-gradient(farthest-side ellipse at 10% 0,#27123B,#191a21);
    box-sizing: border-box;
    background-color: #191a21;
    font-size:13px;
}

// @media only screen and (max-width: 980px) {
//     html, body { font-size: 10px;}
// }

.strong {font-weight:bold;}
.clear {clear:both;}
.show {display:block}
.hide, .none, .hidden {width:0;height:0;display:none;}
.blind,legend{display:block;overflow:hidden;position:absolute;top:-1000em;left:0}
.table_row {display:table-row;}
.table_cell {display:table-cell;}
.box_size {box-sizing: border-box;}
.hidden {z-index:-1; visibility:hidden; overflow:hidden;}
.clear{display:block; float:none; clear:both; height:0; width:100%; font-size:0 !important; line-height:0 !important; overflow:hidden; margin:0 !important; padding:0 !important;}
.input-bg-lock {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABKRJREFUWAnNl0tsVGUUxzvTTlslZUaCloZHY6BRFkp9sDBuqgINpaBp02dIDImwKDG6ICQ8jBYlhg0rxUBYEALTpulMgBlqOqHRDSikJkZdGG0CRqAGUuwDovQ1/s7NPTffnTu3zMxGvuT2vP7n8Z3vu+dOi4r+5xUoJH8sFquamZmpTqfTVeIfCARGQ6HQH83NzaP5xsu5gL6+vuVzc3NdJN1Kkhd8Ev1MMYni4uJjra2tt3wwLvUjCxgYGFg8Pj7+MV5dPOUub3/hX0zHIpFId0NDw6Q/jO4tZOzv76+Znp6+AOb5TBw7/YduWC2Hr4J/IhOD/GswGHy7vb39tyw2S+VbAC1/ZXZ29hKoiOE8RrIvaPE5WvyjoS8CX8sRvYPufYpZYtjGS0pKNoD/wdA5bNYCCLaMYMMEWq5IEn8ZDof3P6ql9pF9jp8cma6bFLGeIv5ShdISZUzKzqPIVnISp3l20caTJsaPtwvc3dPTIx06ziZkkyvY0FnoW5l+ng7guAWnpAI5w4MkP6yy0GQy+dTU1JToGm19sqKi4kBjY+PftmwRYn1ErEOq4+i2tLW1DagsNGgKNv+p6tj595nJxUbyOIF38AwipoSfnJyMqZ9SfD8jxlWV5+fnu5VX6iqgt7d3NcFeUiN0n8FbLEOoGkwdgY90dnbu7OjoeE94jG9wd1aZePRp5AOqw+9VMM+qLNRVABXKkLEWzn8S/FtbdAhnuVQE7LdVafBPq04pMYawO0OJ+6XHZkFcBQA0J1xKgyhlB0EChEWGX8RulsgjvOjEBu+5V+icWOSoFawuVwEordluG28oSCmXSs55SGSCHiXhmDzC25ghMHGbdwhJr6sAdpnyQl0FYIyoEX5CeYOuNHg/NhvGiUUxVgfV2VUAxjtqgPecp9oKoE4sNnbX9HcVgMH8nD5nAoWnKM/5ZmKyySRdq3pCmDncR4DxOwVC64eHh0OGLOcur1Vey46xUZ3IcVl5oa4OlJaWXgQwJwZyhUdGRjqE14VtSnk/mokhxnawiwUvsZmsX5u+rgKamprGMDoA5sKhRCLxpDowSpsJ8vpCj2AUPzg4uIiNfKIyNMkH6Z4hF3k+RgTYz6vVAEiKq2bsniZIC0nTtvMVMwBzoBT9tKkTHp8Ak1V8dTrOE+NgJs7VATESTH5WnVAgfHUqlXK6oHpJEI1G9zEZH/Du16leqHyS0UXBNKmeOMf5NvyislJPB8RAFz4g8IuwofLy8k319fUP1EEouw7L7mC3kUTO1nn3sb02MTFxFpsz87FfJuaH4pu5fF+reDz+DEfxkI44Q0ScSbyOpDGe1RqMBN08o+ha0L0JdeKi/6msrGwj98uZMeon1AGaSj+elr9LwK9IkO33n8cN7Hl2vp1N3PcYbUXOBbDz9bwV1/wCmXoS3+B128OPD/l2LLg8l9APXVlZKZfzfDY7ehlQv0PPQDez6zW5JJdYOXdAwHK2dGIv7GH4YtHJIvEOvvunLCHPPzl3QOLKTkl0hPbKaDUvlTU988xtwfMqQBPQ3m/4mf0yBVlDCSr/CRW0CipAMnGzb9XU1NSRvIX7kSgo++Pg9B8wltxxbHKPZgAAAABJRU5ErkJggg=='); 
    background-repeat: no-repeat; 
    background-attachment: scroll; 
    background-size: 16px 18px; 
    background-position: 98% 50%;
}


/* float */
.fl_l, .ll {float:left;overflow:hidden;}
.fl_r, .rr {float:right;overflow:hidden;}

/* font awesome */
/* i.fa, i.fas, i.far {vertical-align: middle;} */
.pf-char:before{
    font-family: Arial; /* your font family here! */
    font-weight: bold;
    content: attr(data-char-content);
}

/* font-size */
.text-xs { font-size: .75rem; }
.text-sm { font-size: .85rem; }
.f-085 { font-size: .85rem;}
.f-1 { font-size: 1rem;}
.f-2 { font-size: 2rem;}
.f-3 { font-size: 3rem;}
.f-12 { font-size: 1.2rem;}
.f-125 { font-size: 1.25rem;}
.f-15 { font-size: 1.5rem;}


/* color */
.text-white {color:#fff !important;}
.text-black {color:#000 !important;}
.text-gray {color:#898989 !important;}
.text-darkgray {color:#404040 !important;}
.text-yellow {color:#ffc428 !important;}
.text-orange { color:#fbb726 !important;}
.text-blue {color:#5376f8 !important;}
.text-red {color:#f55 !important;}
.text-darkblue {color:#075096 !important;}
.text-darkred {color:#dc3545 !important;}
.text-green {color:#8dc63f !important;}
.text-pink {color:#e431e7 !important;}
.text-skyblue {color:#33ffff !important;}
.text-success {color: #3D3D3D !important;}
.text-fail {color: #ED1C24 !important;}
.text-sky {color:#00C3FF !important;}


.bg-blue { background-color: #0173ff !important;}
.bg-red { background-color:#df0202 !important;}
.bg-green {background-color: #0da700 !important;}
.bg-black {background-color:#000 !important}
.bg-gray {background-color: #474747 !important;}
.bg-orange {background-color:#fbb726 !important;}
.bg-yellow {background-color:#ffc428 !important;}
.bg-darkgray2 {background-color:#303030 !important;}
.bg-whitegray {background-color:#383838 !important;}
.bg-navy {background-color:#363c50 !important;}
.bg-th { background-color: #f8f8f8 !important;}

.border-yellow {border-color:#ffc428 !important;}
.border-gray {border-color: #898989 !important;}
.border-navy {border-color:#363c50 !important;}
.border-darkgray{border-color: #404040 !important;}
.border-table { border:#cccccc !important; }

/* pluse */
@-webkit-keyframes pulse {
    from { opacity: 1;}
    50% { opacity: 0;}
    to {opacity: 1;}
}

@keyframes pulse {
    from {opacity: 1;}
    50% {opacity: 0;}
    to {opacity: 1;}
}

.pulse {
    -webkit-animation: pulse 2.5s ease infinite;
            animation: pulse 2.5s ease infinite;
}


/*
* lock
*/
.lock { position:relative; }
.lock::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    z-index: 1000;
    width:100%;
    height:100%;
    border-radius:5px;
    background: rgba(0,0,0,0.75) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDIxNy4yNDIgMzAwIj4KICA8cGF0aCBpZD0ibG9jayIgZD0iTS01MTMtMTUwLjYyMWExMDguMzUsMTA4LjM1LDAsMCwxLDM2LjIwNy04MC45NjJ2LTM4QTcyLjQxMyw3Mi40MTMsMCwwLDEtNDA0LjM4LTM0MmE3Mi40MTMsNzIuNDEzLDAsMCwxLDcyLjQxNCw3Mi40MTR2MzhhMTA4LjM1LDEwOC4zNSwwLDAsMSwzNi4yMDcsODAuOTYyQTEwOC42MjEsMTA4LjYyMSwwLDAsMS00MDQuMzgtNDIsMTA4LjYyMSwxMDguNjIxLDAsMCwxLTUxMy0xNTAuNjIxWm04OS40My03LjgxOGEyMC42OSwyMC42OSwwLDAsMCw4Ljg0NiwyNS43MTV2MjguNjU1QTEwLjM0NSwxMC4zNDUsMCwwLDAtNDA0LjM4LTkzLjcyNGExMC4zNDUsMTAuMzQ1LDAsMCwwLDEwLjM0NS0xMC4zNDV2LTI4LjY1NWEyMC42ODksMjAuNjg5LDAsMCwwLDkuNzM0LTIzLjExNSwyMC40MzUsMjAuNDM1LDAsMCwwLTE1LTE0Ljg2NSwyMC42OTMsMjAuNjkzLDAsMCwwLTUuMTA4LS42NDJBMjAuNywyMC43LDAsMCwwLTQyMy41NzEtMTU4LjQzOVptNzAuOTE1LTg3Ljc2OHYtMjMuMzhBNTEuNzI0LDUxLjcyNCwwLDAsMC00MDQuMzgtMzIxLjMxLDUxLjcyNCw1MS43MjQsMCwwLDAtNDU2LjEtMjY5LjU4NnYyMy4zOGguMDg5YTEwOC4xMjIsMTA4LjEyMiwwLDAsMSw1MS42MzYtMTMuMDM0LDEwOC4xMjIsMTA4LjEyMiwwLDAsMSw1MS42MzYsMTMuMDM0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTEzLjAwMSAzNDIpIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=') 50% 50% no-repeat;
}

/* confirm */
.confirm { position: relative }
.confirm::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    z-index: 1000;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.75);
}


/* indicator */
.indicator { position: relative; }
.indicator:after {
    content: "";
    position: absolute;
    border-left: .8em solid rgba(0,0,0,0)
}

.indicator.increased:after {
    border-top-width: .8em;
    border-left-width: .8em;
    top: 0;
    right: 0;
    border-top: .8em solid #ec0c87
}

.indicator.decreased:after {
    border-bottom-width: .8em;
    border-left-width: .8em;
    bottom: 0;
    right: 0;
    border-bottom: .8em solid #5376f8
}

.indicator.increased:after {
    -webkit-animation: indicator_blinking_increased .3s 12 alternate forwards,indicator_static_increased .5s 30s forwards;
    animation: indicator_blinking_increased .3s 12 alternate forwards,indicator_static_increased .5s 30s forwards
}

@-webkit-keyframes indicator_blinking_increased { 
    0% { opacity: 1 }
    to { opacity: 0 }
}

@keyframes indicator_blinking_increased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

@-webkit-keyframes indicator_static_increased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

@keyframes indicator_static_increased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

.indicator.decreased:after {
    -webkit-animation: indicator_blinking_decreased .3s 12 alternate forwards,indicator_static_decreased .5s 30s forwards;
    animation: indicator_blinking_decreased .3s 12 alternate forwards,indicator_static_decreased .5s 30s forwards
}

@-webkit-keyframes indicator_blinking_decreased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

@keyframes indicator_blinking_decreased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

@-webkit-keyframes indicator_static_decreased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

@keyframes indicator_static_decreased {
    0% { opacity: 1 }
    to { opacity: 0 }
}

.none { display: none !important}

.page-top {
    position: fixed;
    z-index: 7;
    right: 20px;
    /* bottom: 30px; */
    bottom: 70px;
    width: 60px;
    height: 60px;
}

.page-top button {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.40);
    color: #e9b333;
    border: 1px solid rgba(255, 255, 255, 0.04);
    outline: 0;
    cursor: pointer
}

.page-top button i { margin: 0 }
.page-top button:hover {
    background-color: #212121;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s
}


.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}


button,input,select,textarea {
    background-color: transparent;
    border: 0;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}