/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/_variables-dark.scss";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";

$primary: #0074d9;
$secondary: #93c;
$dark: rgba(0, 0, 0, 0.6);
$fail: #ED1C24;

$utilities: map-merge($utilities, (
    /*
     * color append
     */
    "color": map-merge(map-get($utilities, "color"),(
        values: map-merge(
            map-get(map-get($utilities, "color"), "values"),(
                'yellow': $yellow,
                'orange': $orange, 
                'red': $red,
                'blue': $blue,
                'success': $success,
                'fail': $fail,
                'gray': $gray-600
            ),
        ),
    )),
    /*
     * background color append
     */
    "background-color": map-merge(map-get($utilities, "background-color"),(
        values: map-merge(
            map-get(map-get($utilities, "background-color"), "values"),(
                'yellow': $yellow,
                'orange': $orange, 
                'red': $red,
                'blue': $blue,
                'success': $success,
                'fail': $fail,
                'gray': $gray-600
            ),
        ),
    )),
    /*
     * height
     */
    "height": map-merge(
      map-get($utilities, "height"),
      ( responsive: true ),
    ),
    /*
     * border enable-responsive
     */
    "border": map-merge(
      map-get($utilities, "border"),
      ( responsive: true ),
    ),
    "border-top": map-merge(
      map-get($utilities, "border-top"),
      ( responsive: true ),
    ),
    "border-end": map-merge(
      map-get($utilities, "border-end"),
      ( responsive: true ),
    ),
    "border-bottom": map-merge(
      map-get($utilities, "border-bottom"),
      ( responsive: true ),
    ),
    "border-start": map-merge(
      map-get($utilities, "border-start"),
      ( responsive: true ),
    ),
    "overflow-x": (
        property: overflow-x,
        values: auto hidden visible scroll,
    ),
    "overflow-y": (
        property: overflow-y,
        values: auto hidden visible scroll,
    ),
    "max-width": map-merge(map-get($utilities, "max-width"),(
        responsive: true,
        values: map-merge(
            map-get(map-get($utilities, "max-width"), "values"),(
                '25': 25%,
                '50': 50%, 
            ),
        ),
    )),

    /*
     * z-index
     */
    "z-index": (
        class: z,
        property: z-index,
        values: (
            9999:9999
        )
    )
));

$body-bg: #000;
$body-color: #aaa;

$btn-close-color: #fff;

// form control 
$input-bg: #fff;
$input-focus-bg: #fff;
$input-color: #212529;

$font-size-base: 0.85rem;
$font-family-sans-serif: 'Spoqa Han Sans Neo', "Helvetica Neue", Helvetica, Arial, sans-serif;

$offcanvas-horizontal-width:        300px;
$offcanvas-transition-duration:     .3s;
// $offcanvas-bg-color:rgb(34, 36, 45);
$offcanvas-bg-color: rgb(0,0,0);
$offcanvas-color: #fff;
$offcanvas-box-shadow: 0 12px 14px 0 rgb(0 0 0 / 24%), 0 2px 12px 0 rgb(0 0 0 / 19%);

$modal-content-color: #fff;
$modal-content-bg: rgba(0, 0, 0);
$modal-header-border-color:rgba(255, 255, 255, 0.04);
$modal-content-border-color: rgba(255, 255, 255, 0.04);

@import "../node_modules/bootstrap/scss/bootstrap";

/* swiper */
@import '../node_modules/swiper/swiper-bundle.min.css';


/* Spoqa Han Sans Neo */
@import url("https://cdnjs.cloudflare.com/ajax/libs/spoqa-han-sans/neo/css/SpoqaHanSans-kr.min.css");

/* 
 * font awesome free 
 * yarn add @fortawesome/fontawesome-free
 */
 $fa-font-path: '~@fortawesome/fontawesome-free/webfonts'; 
 @import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
 @import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
 @import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
 
/* flag */
/* https://github.com/lipis/flag-icons */
@import "../node_modules/flag-icons/css/flag-icons.min.css";

@import "../node_modules/normalize.css/normalize.css";
@import "scss/reset";

.text-prematch { color: #4e73df }
.text-inplay { color: #1cc88a }
.text-crossbet { color: #36b9cc }
.text-orange { color: #eb6385}
.text-mini {color: #ffcc56}
.text-casino { color:#c9cbcf}

@-webkit-keyframes growIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes growIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .blink {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  }

  .pulse {
    animation: pulse-animation 2s infinite;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  
  